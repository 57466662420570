/* @flow */

import immer, { original } from 'immer'
import uuid from 'uuid'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import difference from 'lodash/difference'

import {
  createColumnsDependencyGraph,
  createColumnKey,
  createRows,
  createSubSection,
  ensureRowGroup,
  findAllSubsectionSplitsFromInput,
  resolveColumnValues,
  sortSubsections,
} from './shared'
import total_assortment_quantity from '../../configuration/rows/total_assortment_quantity'

import type {
  ColumnConfig,
  RowConfigInput,
  Section,
  UserSplit,
} from '../../types'
import type { Id, Line, Product, Variant } from '../../../../../types'

// 1. Add any missing subsections
// 2. Calculate all rows that should be there (column keys + splits)
// 3. Go through new calculated rows and replace existing or insert new ones
// 4. Remove dead subsections (no lines + no data)
export const addLinesToSections = (
  columns: Array<ColumnConfig>,
  lines: Array<Line>,
  rows: Array<RowConfigInput>,
  sections: Array<Section>,
  splits: Array<UserSplit>,
  matrix: boolean,
  tableData: { [string]: any },
  preview: boolean = false,
  assortmentTotalsMode
) => {
  const rowConfigsByKey = {}
  for (let rowGroup of rows) {
    rowGroup = ensureRowGroup(rowGroup)

    // $FlowFixMe refinement
    for (let rowConfig of rowGroup.configs) {
      rowConfigsByKey[rowConfig.key] = rowConfig
    }
  }

  const updatedSections = immer(sections, updatedSections => {
    for (let [i, section] of updatedSections.entries()) {
      const { product, variants, variantsById } = section
      const linesOfSection = lines.filter(l =>
        section.variantIds.includes(l.variant_id)
      )

      for (let [k, rowGroup] of section.rowGroups.entries()) {
        const [, addedSubSections, removedSubSections] =
          addMissingSubSectionsFromLines(
            product,
            rowConfigsByKey,
            rowGroup,
            columns,
            linesOfSection,
            matrix,
            variants,
            tableData
          )

        for (let [j, subSection] of rowGroup.subSections.entries()) {
          const linesOfSubsection =
            subSection.subSectionKey === 'traede_no_split'
              ? linesOfSection
              : linesOfSection.filter(l => {
                  let matchesSplit = true

                  for (let k in subSection.subSectionSplitData) {
                    const v = subSection.subSectionSplitData[k]

                    if (v !== l[k]) {
                      matchesSplit = false
                      break
                    }
                  }

                  return matchesSplit
                })

          const rowsGroupedByKey = groupBy(subSection.rows, 'key')

          let updatedRows = updatedSections[i].rowGroups[k].subSections[j].rows
          for (let [key, rowsOfKey] of Object.entries(rowsGroupedByKey)) {
            const rowConfig = rowsOfKey[0]

            if (rowConfig.data_source) {
              continue
            }

            const userDefinedSplitsOfRowKey = splits.filter(split => {
              return (
                split.verticalAttributeKey === section.key &&
                split.rowKey === key &&
                split.subSectionKey === subSection.subSectionKey
              )
            })

            const columnsForRowByDependencyGraph = createColumnsDependencyGraph(
              columns.filter(c => c.rows.includes(key)),
              matrix
            )

            const noRowsBefore = rowsOfKey.length
            const newRows =
              calculateAllRowsNeededIncludingColumnValuesAndUserSplits(
                variantsById,
                rowsOfKey,
                columnsForRowByDependencyGraph,
                linesOfSubsection,
                userDefinedSplitsOfRowKey,
                original(section.data),
                preview
              )

            // We have now recalculated the new row values of each key. So for instance,
            // the new quantity columns. Multiple row keys might exist in a subSection
            // (for instance quantity with stock levels below). So now we need to replace
            // the existing rows with the new ones
            const newRowColumnKeys = newRows.map(r => r.columnKey)

            for (let row of newRows) {
              const columnKey = row.columnKey

              const existingRowIndex = updatedRows.findIndex(
                r => r.key === key && r.columnKey === columnKey
              )

              if (existingRowIndex !== -1) {
                // something was updated in the row so we replace it
                if (updatedRows[existingRowIndex] !== row) {
                  updatedRows[existingRowIndex] = row
                }
              } else {
                const currentRowCountOfKeyAndAssortmentKey = updatedRows.filter(
                  r =>
                    r.key === key &&
                    r.assortmentVariantId === row.assortmentVariantId
                ).length

                // We can find another row with same row key and same assortment key,
                // so we want to insert this new row right after it. In case we have multiple
                // assortments we should always strive to insert new assortment rows right after
                // existing rows of same assortment. E.g. if we have Assortment A and Assortment B
                // and we change the price of Assortment A, then new row should replace the Assortment
                // A row - not come after Assortment B
                // this is covered by a test in lines.spec.js
                if (currentRowCountOfKeyAndAssortmentKey !== 0) {
                  const indexOfFirstRowWithSameKeyAndAssortmentKey =
                    updatedRows.findIndex(
                      r =>
                        r.key === key &&
                        r.assortmentVariantId === row.assortmentVariantId
                    )

                  // insert the new row right after the last row with same key and assortment key
                  const insertAtIndex =
                    indexOfFirstRowWithSameKeyAndAssortmentKey +
                    currentRowCountOfKeyAndAssortmentKey

                  updatedRows.splice(insertAtIndex, 0, row)
                } else {
                  updatedRows.push(row)
                }
              }
            }

            // here we filter out any rows that are not present in our
            // new rows calculation.
            // previously we did this *before* we inserted the new rows
            // but we moved it down here to fix the `assortment row` case
            // if we filter out rows before inserting the new ones, then
            // we cannot find an assortment rows previous position
            updatedRows = updatedRows.filter((row, i) => {
              if (row.key !== key) {
                return row
              }

              return newRowColumnKeys.includes(row.columnKey)
            })
          }

          if (matrix && assortmentTotalsMode) {
            let hasAssortmentRows = false
            let hasTotalQuantityRow = false
            let lastQuantityRowIndex = false
            const quantityRows = []
            for (let [rowIndex, row] of updatedRows.entries()) {
              if (row.key === 'quantity' && row.expand_assortments) {
                lastQuantityRowIndex = rowIndex
                quantityRows.push(row)
              }
              if (row.isAssortmentRow) {
                hasAssortmentRows = true
              }
              if (row.key === 'total_quantity') {
                hasTotalQuantityRow = true
              }
            }

            if (
              hasAssortmentRows &&
              !hasTotalQuantityRow &&
              lastQuantityRowIndex !== false
            ) {
              const quantityRowTemplate = quantityRows[quantityRows.length - 1]
              const nonAssortmentVariants = variants.filter(
                v => !v.assortment_id
              )

              const rowValuesByVariantId = {}

              const assortmentVariants = {}
              for (let quantityRow of quantityRows) {
                if (!quantityRow.isAssortmentRow) {
                  for (let [variantId, value] of Object.entries(
                    quantityRow.rowValuesByVariantId
                  )) {
                    if (value == 0) {
                      continue
                    }

                    if (!rowValuesByVariantId[variantId]) {
                      rowValuesByVariantId[variantId] = [0]
                    }

                    rowValuesByVariantId[variantId][0] += value
                  }
                } else {
                  const assortmentQuantity =
                    quantityRow.rowValuesByVariantId[
                      quantityRow.assortmentVariantId
                    ] || 0

                  if (assortmentQuantity == 0) {
                    continue
                  }

                  for (let [
                    horizontalAttribute,
                    horizontalAttributeQuantity,
                  ] of Object.entries(
                    quantityRow.assortmentVariant.assortment
                  )) {
                    const innerAssortmentVariant =
                      section.allVariantsByHorizontalAttribute[
                        horizontalAttribute
                      ]

                    if (innerAssortmentVariant) {
                      if (!rowValuesByVariantId[innerAssortmentVariant.id]) {
                        rowValuesByVariantId[innerAssortmentVariant.id] = [0]
                      }

                      rowValuesByVariantId[innerAssortmentVariant.id][0] +=
                        horizontalAttributeQuantity * assortmentQuantity

                      assortmentVariants[innerAssortmentVariant.id] =
                        innerAssortmentVariant
                    }
                  }
                }
              }

              const combinedVariants = [...nonAssortmentVariants]
              const combinedVariantIds = nonAssortmentVariants.map(v => v.id)
              for (let variant of Object.values(assortmentVariants)) {
                if (!combinedVariantIds.includes(variant.id)) {
                  combinedVariants.push(variant)
                  combinedVariantIds.push(variant.id)
                }
              }

              const newRows = createRows(
                product,
                {
                  ...quantityRowTemplate,
                  ...total_assortment_quantity({
                    assortmentTotalsMode,
                  }),
                  data_source: 'assortment_totals',
                  editable_variants: [],
                  expand_assortments: false,
                  assortmentQuantity: 1,
                  assortmentVariant: null,
                  assortmentVariantId: null,
                  isAssortmentRow: false,
                },
                columns,
                {
                  assortment_totals: rowValuesByVariantId,
                },
                // e.g. if we have a variant from an assortment where there
                // is no line for the variant itself, but it has a quantity
                // through the assortment, then we need to add the variant to
                // the rows variants
                combinedVariants,
                matrix,
                quantityRowTemplate.columnKey,
                'data_source'
              )

              updatedRows.splice(lastQuantityRowIndex + 1, 0, ...newRows)
            }
          }

          if (
            updatedRows !== updatedSections[i].rowGroups[k].subSections[j].rows
          ) {
            updatedSections[i].rowGroups[k].subSections[j].rows = updatedRows
          }
        }

        if (addedSubSections > 0 || removedSubSections > 0) {
          if (rowGroup.subSections.length > 1) {
            // imagine that data generated 1 subSection: batch=null and now
            // lines add a new one batch=b01. Unless the batch=null section is
            // explicitly kept alive, e.g. by having some inventory rows or
            // similar with batch=null we will remove if it has no data and no lines
            rowGroup.subSections = rowGroup.subSections.filter(subSection => {
              let hasLines = false
              let hasData = false
              for (let row of subSection.rows) {
                if (row.lines.length > 0) {
                  hasLines = true
                  break
                }
                if (
                  Object.values(row.dataByVariantId).filter(
                    data => data.length > 0
                  ).length > 0
                ) {
                  hasData = true
                  break
                }
              }

              return hasLines || hasData
            })
          }

          rowGroup.subSections = sortSubsections(rowGroup.subSections)
        }
      }

      const calculatedTotalRows = updatedSections[i].rowGroups.reduce(
        (carry, rowGroup) => {
          return rowGroup.subSections.reduce((innerCarry, subSection) => {
            return (innerCarry += subSection.rows.length)
          }, carry)
        },
        0
      )

      if (calculatedTotalRows != updatedSections[i].totalRowCount) {
        updatedSections[i].totalRowCount = calculatedTotalRows
      }
    }
  })

  return updatedSections
}

const addMissingSubSectionsFromLines = (
  product: Product,
  rowConfigsByKey,
  rowGroup,
  columns,
  linesOfSection,
  matrix,
  variants,
  tableData
) => {
  let added = 0
  let removed = 0
  const splitKeys = findAllSubsectionSplitsFromInput(
    [[linesOfSection]],
    rowGroup.split_by,
    tableData
  )

  for (let split of splitKeys) {
    const existingSplitIndex = rowGroup.subSections.findIndex(
      s => s.subSectionKey === split.key
    )

    if (existingSplitIndex === -1) {
      const newSubSection = createSubSection(
        split.key,
        split.label,
        split.data,
        rowGroup.subSections[0].rows.reduce((carry, row) => {
          const dataOfSubSection = {}

          const newRows = createRows(
            product,
            rowConfigsByKey[row.key],
            columns,
            // there is no data because otherwise the subsection would already be there
            dataOfSubSection,
            variants,
            matrix,
            split.key,
            'lines',
            rowGroup.split_by
          )

          return carry.concat(newRows)
        }, [])
      )

      rowGroup.subSections.push(newSubSection)
      added += newSubSection.rows.length
    }
  }

  return [rowGroup, added, removed]
}

export const calculateAllRowsNeededIncludingColumnValuesAndUserSplits = (
  variantsById: { [Id]: Variant },
  rowsOfKey: Array<Row>,
  columnsForRowByDependencyGraph: Array<ColumnConfig>,
  lines: Array<Line>,
  userDefinedSplits: Array<UserSplit>,
  data,
  preview
) => {
  const rowConfig = rowsOfKey[0]
  const splittableColumns = columnsForRowByDependencyGraph.filter(
    c => c.split_by_values
  )
  const linesByColumnGroup = {}
  const foundColumnKeys = []
  const columnKeyIncrementByVariantColumnKey = {}
  for (let line of lines) {
    const variant = variantsById[line.variant_id]

    const splittableColumnValues = resolveColumnValues(
      {},
      splittableColumns,
      {
        data,
        line,
        rowKey: rowConfig.key,
        variant,
      },
      {},
      {}
    )

    let columnKey = createColumnKey(splittableColumnValues)
    if (variant.assortment_id && rowConfig.expand_assortments) {
      columnKey += `assortment:${variant.assortment_id}`
    }

    // Create extra row if a line was already found for a variant in this column key
    // Previously we had issues where a quantity field would contain 2 underlying lines
    // which caused double quantity (so entering 5 would result in 10). Here we make sure
    // that a new column group is created in case a variant has already been added to
    // a column key
    const variantColumnKey = `${line.variant_id}-${columnKey}`
    if (!columnKeyIncrementByVariantColumnKey[variantColumnKey]) {
      columnKeyIncrementByVariantColumnKey[variantColumnKey] = 0
    }

    let useColumnKey = columnKey
    const columnIncrement =
      columnKeyIncrementByVariantColumnKey[variantColumnKey] || 0

    if (columnIncrement > 0) {
      useColumnKey = `${columnKey}-${columnIncrement}`
    }

    if (!linesByColumnGroup[useColumnKey]) {
      const useColumnValues = { ...splittableColumnValues }
      if (columnIncrement > 0) {
        useColumnValues.line_increment = columnIncrement
      }

      linesByColumnGroup[useColumnKey] = {
        columnValues: useColumnValues,
        columnKey: useColumnKey,
        lines: [],
        source: 'lines',
      }

      foundColumnKeys.push(useColumnKey)
    }

    linesByColumnGroup[useColumnKey].lines.push(line)

    columnKeyIncrementByVariantColumnKey[variantColumnKey]++
  }

  for (let userDefinedSplit of userDefinedSplits) {
    // When creating a new split for a 2-splittable column table (text + unit_pice)
    // will initially be text=null, price=base which means it will have the same column
    // key as the base row. Therefore, as long as a new text has not been entered
    // we mark the key as a draft to ensure it's not merged into existing rows.
    let splitKey = userDefinedSplit.columnKey
    if (userDefinedSplit.draft) {
      splitKey += '----draft-----'
    }

    if (!foundColumnKeys.includes(splitKey)) {
      linesByColumnGroup[splitKey] = {
        assortmentVariant: userDefinedSplit.assortmentVariant,
        assortmentVariantId: userDefinedSplit.assortmentVariantId,
        columnValues: userDefinedSplit.columnValues,
        columnKey: splitKey,
        editable_variants: userDefinedSplit.editableVariants,
        isAssortmentRow: userDefinedSplit.isAssortmentRow,
        splitId: userDefinedSplit.id,
        lines: [],
        source: 'split',
      }
    }
  }

  const linesByColumnGroupAsArray = Object.values(linesByColumnGroup)

  const nonSplittableColumns = columnsForRowByDependencyGraph.filter(
    c => !c.split_by_values
  )

  let updatedRows = [...rowsOfKey]
  let rowsWereUpdated = false
  for (let columnGroup of linesByColumnGroupAsArray) {
    let index = updatedRows.findIndex(
      r => r.columnKey === columnGroup.columnKey
    )
    let existingRow = index !== -1 ? updatedRows[index] : null

    let recalculate = false
    let insertNewRowAtIndex = null
    if (!existingRow) {
      let rowToCopyIndex = rowsOfKey.findIndex(row => {
        const linesOfColumnGroup = columnGroup.lines

        if (linesOfColumnGroup.length == 0) {
          return false
        }

        return row.editable_variants.includes(linesOfColumnGroup[0].variant_id)
      })

      if (rowToCopyIndex === -1) {
        rowToCopyIndex = 0
      }

      const rowToCopy = rowsOfKey[rowToCopyIndex]
      insertNewRowAtIndex = rowToCopyIndex

      existingRow = {
        ...rowToCopy,
        ...columnGroup,
      }
      recalculate = true
    } else {
      // lines were added or removed
      if (existingRow.lines.length !== columnGroup.lines.length) {
        recalculate = true
        // same number of lines, so we check if any updated
      } else {
        for (let line of columnGroup.lines) {
          const identicalLine = existingRow.lines.find(l => l === line)

          if (!identicalLine) {
            recalculate = true
            break
          }
        }
      }
    }

    if (recalculate) {
      const rowProperty = existingRow.edit_property
        ? existingRow.edit_property
        : existingRow.key
      const rowColliProperty = existingRow.edit_colli_property
      const linesByVariantId = groupBy(
        columnGroup.lines,
        line => line.variant_id
      )

      const rowValuesByVariantId = {}
      const rowValuesCollisByVariantId = {}
      const editableVariants = [...existingRow.editable_variants]

      for (let [variantId, linesOfVariant] of Object.entries(
        linesByVariantId
      )) {
        const value = linesOfVariant.reduce((carry, line) => {
          const lineValue = rowConfig.value
            ? parseInt(rowConfig.value(line))
            : parseInt(line[rowProperty])

          return (carry += lineValue)
        }, 0)

        const colli =
          rowColliProperty && linesOfVariant[0][rowColliProperty]
            ? parseInt(linesOfVariant[0][rowColliProperty])
            : null

        rowValuesByVariantId[variantId] = value
        rowValuesCollisByVariantId[variantId] = colli

        if (!editableVariants.includes(linesOfVariant[0].variant_id)) {
          editableVariants.push(linesOfVariant[0].variant_id)
        }
      }

      const columnValues = resolveColumnValues(
        columnGroup.columnValues,
        nonSplittableColumns,
        {
          assortmentQuantity: existingRow.assortmentQuantity,
          data,
          lines: columnGroup.lines,
          linesByVariantId,
          rowKey: existingRow.key,
        },
        rowValuesByVariantId,
        rowValuesCollisByVariantId
      )

      existingRow = {
        ...existingRow,
        columnValues,
        editable_variants: editableVariants,
        lines: columnGroup.lines,
        linesByVariantId,
        rowValuesByVariantId,
        rowValuesCollisByVariantId,
        source: columnGroup.source,
      }

      if (index === -1) {
        const newRow = {
          ...existingRow,
          id: existingRow.source === 'split' ? existingRow.splitId : uuid(),
        }

        if (insertNewRowAtIndex !== -1) {
          updatedRows.splice(insertNewRowAtIndex, 0, newRow)
        } else {
          updatedRows.push(newRow)
        }
      } else {
        updatedRows[index] = existingRow
      }

      rowsWereUpdated = true
    }
  }

  //
  // Here we remove any data generated rows, that can be replaced with one
  // generated by lines.
  // Example:
  //   - the base unit price is 100 DKK so there is a line with price=100 DKK.
  //   - the only order line in the table is one with price=90 DKK
  //
  // In this case instead of having 2 rows (1 empty with price=100 DKK + 1 with price=90 DKK)
  // we just have 1 line. So since there are no order lines in the rows generated
  // by data we replace them with the ones generated by lines

  const rowCountBefore = updatedRows.length

  if (preview) {
    updatedRows = updatedRows.filter(row => row.lines.length > 0)
  } else {
    // When creating subsections from lines we do not pass a long the line to
    // create the proper column key. This generates a subsection with the wrong
    // columnKey which is later replaced by correct rows generated from lines.
    // We SHOULD be able to safely remove any rows generated from lines that dont
    // have any lines so that is what we do here.
    // Ref: the test "should create property column key when creating missing subsections from lines" in lines.spec.js
    updatedRows = updatedRows.filter(
      row => row.source !== 'lines' || row.lines.length > 0
    )

    const lineOrSplitRows = updatedRows.filter(
      row => row.source === 'lines' || row.source === 'split'
    )
    const variantIdsFoundInLineOrSplitRows = []
    for (let lineOrSplitRow of lineOrSplitRows) {
      for (let variantId of lineOrSplitRow.editable_variants) {
        variantIdsFoundInLineOrSplitRows.push(variantId)
      }
    }

    const rowsByColumnKeys = groupBy(
      updatedRows,
      row => `${row.key}-${row.columnKey}`
    )

    const hasLinesOrSplitByVariantsKey = {}
    for (let row of updatedRows) {
      if (row.source === 'lines' || row.source === 'split') {
        const editableVariants =
          original(row.editable_variants) || row.editable_variants

        const key = editableVariants.join('-')

        hasLinesOrSplitByVariantsKey[key] = true
      }
    }

    updatedRows = updatedRows.filter(row => {
      const isLineOrSplitRow = row.source === 'lines' || row.source === 'split'

      if (isLineOrSplitRow) {
        return true
      }

      const key = row.editable_variants.join('-')

      return hasLinesOrSplitByVariantsKey[key] !== true
    })

    /*for (let [_, rowsOfColumnKey] of Object.entries(rowsByColumnKeys)) {
      const key = rowsOfColumnKey[0].key
      const columnKey = rowsOfColumnKey[0].columnKey

      const rowsWithLines = rowsOfColumnKey.filter(r => r.lines.length > 0)

      if (rowsWithLines.length > 0) {
        updatedRows = updatedRows.filter(r => {
          return true
          const isGeneratedFromLinesOrSplit =
            r.source === 'lines' || r.source === 'split'

          const variantIdsNotFoundInLinesOrSplits = difference(
            r.editable_variants,
            variantIdsFoundInLineOrSplitRows
          )
          const noVariantsOfRowHasLineOrSplit =
            r.editable_variants.length > 0 &&
            r.editable_variants.length === variantIdsNotFoundInLinesOrSplits

          return isGeneratedFromLinesOrSplit || noVariantsOfRowHasLineOrSplit

          // LEGACY TEST
          return (
            (r.source === 'lines' || r.source === 'split') &&
            (r.key != key || r.columnKey != columnKey || r.lines.length > 0)
          )
        })
      }
    }*/
  }

  const rowCountAfter = updatedRows.length

  if (rowCountBefore != rowCountAfter) {
    rowsWereUpdated = true
  }

  /*
  const rowsWithLines = updatedRows.filter(r => r.lines.length > 0)

  if (rowsWithLines.length > 0) {
    updatedRows = updatedRows.filter(
      r => r.source !== 'data' || r.lines.length > 0
    )
  }

  const allColumnKeys = linesByColumnGroupAsArray.map(g => g.columnKey)

  const splitRowsWithoutAFoundColumnKey = updatedRows.filter(
    r => r.source === 'split' && !allColumnKeys.includes(r.columnKey)
  )
  if (splitRowsWithoutAFoundColumnKey.length > 0) {
    updatedRows = updatedRows.filter(
      r => r.source !== 'split' || allColumnKeys.includes(r.columnKey)
    )
    rowsWereUpdated = true
  }

  const linesRowsWithoutAFoundColumnKey = updatedRows.filter(
    r => r.source === 'lines' && !allColumnKeys.includes(r.columnKey)
  )
  if (linesRowsWithoutAFoundColumnKey.length > 0) {
    /*updatedRows = updatedRows.filter(
      r => r.source !== 'lines' || allColumnKeys.includes(r.columnKey)
    )
    rowsWereUpdated = true
  }*/
  ////console.log('updated', rowsWereUpdated, updatedRows.map(r => original(r) || r))
  return rowsWereUpdated ? updatedRows : rowsOfKey
}
