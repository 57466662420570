/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'

import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import { PriceInput } from '../../../../infrastructure/components/Formik'
import UpdateUnitPriceModal from './UpdateUnitPriceModal'

import { IconButton } from './shared'

import { msg } from '../../../shared'
import type { Product, Variant } from '../../../types'

type Props = {
  allowUpdatingDiscountPercentage: boolean,
  allowUpdatingNetPrice: boolean,
  context: Object,
  edit?: boolean,
  editable?: boolean,
  onEdit: (editMode: boolean) => void,
  product: Product,
  showDiscount?: boolean,
  variants: Array<Variant>,
}

const UnitPrice = ({
  assortmentQuantity = 1,
  assortmentShowUnitPrice = false,
  allowUpdatingDiscountPercentage = true,
  allowUpdatingNetPrice = true,
  context,
  dataPriceExtractor,
  edit = false,
  editable = false,
  editProperty = 'net_price',
  employeeDiscountEditable = true,
  lines,
  mapPropertiesOnModalSave,
  onChange,
  onEdit,
  price,
  priceProperty = 'net_price',
  product,
  showDiscount = true,
  variants,
}: Props) => {
  const [editMode, setEditMode] = React.useState('row')

  const usePrice = price[0]
  const inputPrices = React.useMemo(() => {
    if (!usePrice) {
      return []
    }

    return [usePrice]
  }, [usePrice])

  const displayPrice = React.useMemo(() => {
    if (!usePrice) {
      return 0
    }

    if (priceProperty !== 'price_before_discount') {
      return usePrice[priceProperty]
    }

    if (!usePrice.net_price) {
      return usePrice.price_before_discount
    }

    // in case net price is higher than price before discount we want to display net price
    if (
      usePrice.net_price &&
      usePrice.net_price > usePrice.price_before_discount
    ) {
      return usePrice.net_price
    }

    return usePrice.price_before_discount
  }, [priceProperty, usePrice])

  const onSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      const updates = []

      const throwError = m => {
        msg('error', m)
        setSubmitting(false)
      }

      // when changing line prices we need the new prices to match exactly how they are
      // later returned by the API.
      // E.g. let's say we add a discount 20% and the discount_amount comes out to 100.10000002
      // Once the line is persisted it will be returned by the API as 100.1
      // This is important because of product table column keys. If the 2 do not match then
      // the rows will not get merged
      const normalizeNumberToApiResponse = (number, dec) => {
        return parseFloat(number.toFixed(dec))
      }

      for (let chunkData of values.lines) {
        if (!chunkData.prices.update_property) {
          continue
        }

        let updatedPrice = {
          price_before_discount: 0,
          discount_amount: 0,
          discount_percentage: 0,
          net_price: 0,
        }

        if (price[0]) {
          updatedPrice = {
            ...updatedPrice,
            ...price[0],
          }
        }

        if (chunkData.prices.update_property.key === 'net_price') {
          const parsedPrice = parseFloat(chunkData.prices.net_price)

          if (isNaN(parsedPrice)) {
            return throwError(
              `${chunkData.prices.net_price} is not a valid price`
            )
          }

          updatedPrice.net_price = parsedPrice
          updatedPrice.discount_amount =
            updatedPrice.price_before_discount == 0
              ? 0
              : updatedPrice.price_before_discount - parsedPrice

          let discountPercentage = 0

          if (updatedPrice.net_price > updatedPrice.price_before_discount) {
            discountPercentage = 0
          } else if (updatedPrice.net_price == 0) {
            discountPercentage = 100
          } else if (updatedPrice.price_before_discount == 0) {
            discountPercentage = 0
          } else {
            discountPercentage = normalizeNumberToApiResponse(
              (100 / updatedPrice.price_before_discount) *
                updatedPrice.discount_amount,
              2
            )
          }

          updatedPrice.discount_percentage = discountPercentage
          updatedPrice.discount_method = 'price'
        } else if (
          chunkData.prices.update_property.key === 'discount_percentage'
        ) {
          let parsedDiscount = parseFloat(chunkData.prices.discount_percentage)

          if (isNaN(parsedDiscount)) {
            return throwError(
              `${chunkData.prices.discount_percentage} is not a valid discount %`
            )
          }

          if (parsedDiscount < 0) {
            parsedDiscount = 0
          }

          updatedPrice.discount_percentage = parsedDiscount
          updatedPrice.discount_amount =
            parsedDiscount == 100
              ? updatedPrice.price_before_discount
              : normalizeNumberToApiResponse(
                  (updatedPrice.price_before_discount / 100) * parsedDiscount,
                  4
                )

          updatedPrice.net_price = normalizeNumberToApiResponse(
            updatedPrice.price_before_discount - updatedPrice.discount_amount,
            4
          )
          updatedPrice.discount_method = 'percentage'
        }

        const updatedNetPrice = updatedPrice.net_price
        delete updatedPrice.net_price

        // For Production orders
        // If we are changing the price of existing lines then we are editing the lines data directly. Here
        // we need to use editProperty
        // For splits (creating new splits) we are changing the columnKey and therefore cannot use editProperty
        const splitUpdate = {
          ...updatedPrice,
          net_price: updatedNetPrice,
        }
        let lineUpdate = {
          ...updatedPrice,
          [editProperty]: updatedNetPrice,
        }

        if (mapPropertiesOnModalSave) {
          lineUpdate = mapPropertiesOnModalSave(lineUpdate, values)
        }

        updates.push({
          lineUpdate,
          // it's really important that we use the dataPriceExtractor here, because we want the columnKey
          // of our split to have the same properties *and be in the same order* as our other rows column keys
          // the issue we have seen is in ShowOrderV3 if we update net_price, then it's suddenly placed at the
          // end of the price object after employee_discount, but the API returns prices where net_price is before
          // employee_discount. The end result is that the columnKey of the split is different from the columnKey of
          // rows generated from persisted lines or pricing data
          splitUpdate: dataPriceExtractor(splitUpdate),
          lines: chunkData.lines,
        })
      }

      for (let updateChunk of updates) {
        onChange(
          updateChunk.lineUpdate,
          updateChunk.splitUpdate,
          updateChunk.lines
        )
      }

      setSubmitting(false)
    },
    [
      dataPriceExtractor,
      editProperty,
      editMode,
      mapPropertiesOnModalSave,
      onChange,
      price,
    ]
  )

  let useIsEditable = editable
  // E.g. in B2B webshop the employee discount is a separate button
  // so it should not also be editable in the price input
  if (!employeeDiscountEditable && usePrice && usePrice.employee_discount) {
    useIsEditable = false
  }

  return (
    <UnitPriceContainer>
      {edit === true && (
        <UpdateUnitPriceModal
          allowUpdatingDiscountPercentage={allowUpdatingDiscountPercentage}
          allowUpdatingNetPrice={allowUpdatingNetPrice}
          currency={context.currency}
          editMode={editMode}
          inputPrices={inputPrices}
          lines={lines}
          onHide={() => onEdit(false)}
          onSave={onSubmit}
          product={product}
          setEditMode={setEditMode}
          show={edit === true}
          variants={variants}
        />
      )}

      {useIsEditable === true && edit === false && (
        <IconButton
          size="small"
          onClick={() => onEdit(true)}
          type="button"
          tabIndex="-1"
        >
          <span className="glyphicon glyphicon-pencil" />
        </IconButton>
      )}

      {usePrice && (
        <div>
          {!assortmentShowUnitPrice && (
            <FormatCurrency
              currency={context.currency}
              style={{ whiteSpace: 'nowrap' }}
            >
              {displayPrice}
            </FormatCurrency>
          )}
          {assortmentShowUnitPrice && (
            <FormatCurrency
              currency={context.currency}
              style={{ whiteSpace: 'nowrap' }}
            >
              {displayPrice / assortmentQuantity}
            </FormatCurrency>
          )}
          <DiscountContainer>
            {usePrice.employee_discount && (
              <EmployeeDiscountLabel>(Employee)</EmployeeDiscountLabel>
            )}
            {showDiscount && usePrice.discount_percentage != 0 && (
              <DiscountLabel>{usePrice.discount_percentage}%</DiscountLabel>
            )}
          </DiscountContainer>
        </div>
      )}
      {!usePrice && <div>0</div>}
    </UnitPriceContainer>
  )
}

export default UnitPrice

const UnitPriceContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const UnitPriceInputContainer = styled.div`
  display: flex;
  min-width: 165px;
`

const UnitPriceFormSaveButton = styled.button.attrs({
  type: 'submit',
})`
  background: transparent;
  border: none;
  outline: 0;
`

const StyledPriceInput = styled(PriceInput).attrs({ bsSize: 'sm' })`
  border: none;
  box-shadow: none;
  border-bottom: 1px dashed #d8d8d8;
  outline: 0;
  text-align: right;

  &:focus {
    border-color: inherit;
    outline: 0;
    box-shadow: none;
  }
`

const DiscountContainer = styled.div``

const EmployeeDiscountLabel = styled.span`
  color: #acb4b4;
  font-size: 9px;
  font-style: italic;
  margin-right: 3px;
`

const DiscountLabel = styled.span`
  color: red;
  font-size: 9px;
`
