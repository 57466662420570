/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import { SessionContext } from '../../../../../../shared'
import HoverImage from '../../../HoverImage'
import {
  ProductTableContext,
  TableRow,
  TableColumn,
  createEmptyArray,
} from '../../../shared'
import {
  VerticalAttributeSplitRow,
  createFieldId,
  fieldIdToString,
  columnConfigToStyle,
  renderValue,
  resolveColliProps,
  createDefaultLineData,
} from '../../shared'
import QuantityField from '../QuantityField'
import SplitButton from '../SplitButton'

import { mapSectionsToTraditionalRows } from './data'

import type {
  ColumnValues,
  Row,
  RowAction,
  Section,
  UserSplitDispatcher,
} from '../../../types'
import type { Product, Variant } from '../../../../../../types'

type Props = {
  columnValues: ColumnValues,
  flattenedActions: Array<RowAction>,
  flattenedRows: Array<Row>,
  horizontalAttributeValues: Array<string>,
  maxHorizontalColumns: number,
  noActionColumns: number,
  product: Product,
  section: Section,
  splitsDispatcher: UserSplitDispatcher,
  variantsByHorizontalAttribute: { [string]: Variant },
  variantsById: { [string]: Variant },
}

const TraditionalVariantRow = ({
  activeField,
  columnValues = {},
  dataContext,
  editColumn = false,
  flattenedActions,
  flattenedRows,
  hasNoos,
  horizontalAttributeValues,
  isLast,
  maxHorizontalColumns,
  noActionColumns,
  onClearDataCache,
  onColumnValueChange,
  onControlKey,
  onEditColumn,
  onUpdateLines,
  product,
  section,
  setActiveField,
  showNoosLabel,
  splitsDispatcher,
  tableSectionData,
  tableSectionKey,
  variantsByHorizontalAttribute,
  variantsById,
  verticalAttributes,
}: Props) => {
  const { entity: userEntity, user } = React.useContext(SessionContext)
  const context = React.useContext(ProductTableContext)
  const {
    attribute_codes,
    brand,
    brandSettings,
    columns,
    defaultRowData,
    imageSettings,
    noProductHeadersMode,
    productImage,
    preview,
    rows,
    variantImages,
  } = context

  const hasSplittableRow = flattenedRows.filter(r => r.splittable).length > 0

  const {
    data,
    rowGroups,
    totalRowCount,
    key,
    verticalAttributesCodes,
    verticalAttributesObject: verticalAttributesValues,
    variants,
  } = section

  const verticalAttributesLabel = React.useMemo(() => {
    const label = []

    for (let attribute of verticalAttributes) {
      const attributeValueLabel = []

      if (!verticalAttributesValues[attribute]) {
        continue
      }

      if (
        attribute_codes.includes(attribute) &&
        verticalAttributesCodes[attribute]
      ) {
        attributeValueLabel.push(verticalAttributesCodes[attribute])
      }

      const value = verticalAttributesValues[attribute]
      attributeValueLabel.push(value)

      label.push(attributeValueLabel.join(' '))
    }

    return label.join(' ')
  }, [
    attribute_codes,
    verticalAttributes,
    verticalAttributesCodes,
    verticalAttributesValues,
  ])

  const keyGen = key => `${variant.id}-${key}`
  const variant = variants[0]

  const {
    displaySubsections,
    finalRowsBySubSectionKey,
    splittableSubSectionProperties,
  } = React.useMemo(() => {
    return mapSectionsToTraditionalRows(rowGroups)
  }, [rowGroups])

  let columnCount = 0
  let rowCount = 0
  let actionCount = 0

  const generatedRows = []
  for (let [subSectionKey, rowsOfSubsection] of Object.entries(
    finalRowsBySubSectionKey
  )) {
    for (let [
      i,
      { columnKey, rows, subSection },
    ] of rowsOfSubsection.entries()) {
      // In traditional style table we have a row per subSection. That means that every row
      // within a subSection will have the same lines, actions etc. So we pull out the first row
      // here to define these things
      const editableRow = rows.find(r => r.editable)
      let primaryRow = editableRow
      if (!primaryRow) {
        primaryRow = rows.find(r => r.source == 'lines')
      }
      if (!primaryRow) {
        primaryRow = rows[0]
      }

      if (!primaryRow) {
        continue
      }

      const { lines } = primaryRow

      const useActions = [...flattenedActions]
      if (hasSplittableRow) {
        const splittableColumns = columns.filter(
          c => c.editable && c.split_by_values
        )

        if (
          splittableColumns.length > 0 ||
          splittableSubSectionProperties.length > 0
        ) {
          useActions.unshift({
            key: 'splittable',
            render: () => {
              const createSplit = column => {
                splitsDispatcher({
                  column,
                  row: primaryRow,
                  type: 'split',
                  subSectionKey: subSection.subSectionKey,
                  verticalAttributeKey: key,
                })
              }

              const onCreateSubSection = (splitData, splitKey, updateData) => {
                const existingSubSection = rowGroups[0].subSections.find(
                  s => s.subSectionKey == splitKey
                )
                const existingRow = existingSubSection
                  ? existingSubSection.rows.find(r => r.key === primaryRow.key)
                  : null
                const existingLine =
                  (existingRow ? existingRow.lines : [])[0] || null

                onUpdateLines(
                  {
                    createOnMissing: true,
                    defaultRowData: createDefaultLineData(
                      tableSectionData,
                      defaultRowData,
                      {
                        splitKey: splitKey,
                        subSectionSplitData: splitData,
                      },
                      columns,
                      primaryRow
                    ),
                    product,
                    variants: [variant],
                    lines: existingLine ? [existingLine] : [],
                    type: 'update',
                    updates: updateData,
                  },
                  primaryRow,
                  splitKey
                )
              }

              return (
                <SplitButton
                  columns={splittableColumns}
                  onCreateSubSection={onCreateSubSection}
                  onSplit={createSplit}
                  row={primaryRow}
                  subSectionProperties={splittableSubSectionProperties}
                />
              )
            },
          })
        }
      }

      const noEmptyActionsColumns = noActionColumns - useActions.length

      const variantRow = [
        ((productImage === true || variantImages === true) && i === 0 && (
          <VariantImageColumn
            key={keyGen(`${columnKey}-image`)}
            className="product-table-hover-column"
            rowSpan={rowsOfSubsection.length}
            style={{
              width: imageSettings.column_width,
              minWidth: imageSettings.column_width - 20,
            }}
          >
            {variantImages === true && <HoverImage image={variant.picture} />}
          </VariantImageColumn>
        ): null),
        <TableColumn
          className="product-table-hover-column"
          key={keyGen(`${columnKey}-vertical-attributes-label`)}
        >
          {noProductHeadersMode && (
            <ProductNameLabel>{product.name}</ProductNameLabel>
          )}
          {verticalAttributesLabel}
          {displaySubsections && <div>{subSection.subSectionLabel}</div>}
          {showNoosLabel && hasNoos && <div>(NOOS)</div>}
        </TableColumn>,
      ]

      //
      // ROWS
      //
      rowCount = rows.length
      for (let row of rows) {
        const {
          data_source,
          columnValues,
          dataByHorizontalValue,
          linesByVariantId,
          rowValuesByVariantId,
          rowValuesCollisByVariantId,
          splittable,
        } = row

        const value = variant ? rowValuesByVariantId[variant.id] : null
        const valueColli = variant
          ? rowValuesCollisByVariantId[variant.id]
          : null

        let mode
        if (row.editable === true) {
          mode = 'editable'
        }

        const rowValuesSiblings = {}
        for (let siblingRow of subSection.rows) {
          if (siblingRow === row) {
            continue
          }

          // TODO
          // rowValuesSiblings[siblingRow.key] =
          //  siblingRow.rowValuesByHorizontalAttribute[attribute]
        }

        let lines = []
        if (variant) {
          lines = linesByVariantId[variant.id] || []
        }

        let style = {}
        if (row.style && typeof row.style === 'function') {
          style = row.style({
            rowValuesSiblings,
            matrix: false,
            value,
          })
        }

        let suffixRenderFunction
        if (typeof row.suffix_render === 'function') {
          suffixRenderFunction = row.suffix_render
        }

        let disabled = false
        if (typeof row.disabled === 'function') {
          disabled = row.disabled({
            lines,
            variant,
          })
        }

        const { availableColli, defaultColli } = resolveColliProps({
          brandSettings,
          customer: dataContext.customer,
          product,
          variant,
          user,
        })

        const fieldId = variant
          ? createFieldId(
              tableSectionKey,
              product.id,
              variant.id,
              subSectionKey,
              row.key,
              row.columnKey
            )
          : false
        const showField = fieldId
          ? activeField &&
            fieldIdToString(activeField) === fieldIdToString(fieldId)
          : false

        const onChange = updateData => {
          onUpdateLines(
            {
              createOnMissing: true,
              defaultRowData: createDefaultLineData(
                tableSectionData,
                defaultRowData,
                subSection,
                columns,
                row
              ),
              product,
              variants: [variant],
              lines: lines,
              type: 'update',
              updates: updateData,
            },
            row,
            subSectionKey
          )
        }

        let rowSpan
        if (data_source) {
          rowSpan = rowsOfSubsection.length
        }

        const columnStyle = {
          ...columnConfigToStyle({
            ...row,
            width: 100 + (row.suffix_render_width || 0),
          }),
          ...style,
        }

        if (rowSpan > 1 && i > 0) {
          continue
        }

        variantRow.push(
          <TableColumn
            className="product-table-hover-column"
            key={keyGen(`${columnKey}-${row.key}`)}
            mode={mode}
            rowSpan={rowSpan}
            style={columnStyle}
          >
            {mode !== 'disabled' && (
              <QuantitySuffixContainer
                hasSuffix={suffixRenderFunction !== undefined}
              >
                {row.editable !== true &&
                  renderValue({
                    brand,
                    config: {
                      ...row,
                      rowValuesSiblings,
                      // Passing maxHorizontalColumns & columns so rows like availability row can be responsive
                      // (meaning so it can alter its display based on the number of columns & available
                      // viewport width)
                      maxHorizontalColumns,
                      columns,
                    },
                    data: {
                      context: dataContext,
                      data,
                      onClearDataCache,
                      onChange,
                      lines,
                      variant,
                    },
                    value,
                    valueColli,
                    userEntity,
                  })}
                {row.editable === true && (
                  <QuantityFieldContainer
                    hasSuffix={suffixRenderFunction !== undefined}
                  >
                    <QuantityField
                      availableColli={availableColli}
                      colliIncludedInValue={
                        row.colli_not_included_in_value !== true
                      }
                      defaultColli={defaultColli}
                      disabled={disabled}
                      enableColli={row.enable_colli}
                      id={fieldIdToString(fieldId)}
                      onBlur={() => {
                        // In case we tab an onBlur event will also be fired when we focus away from this input.
                        // In such a case we want to ignore the event to prevent deactivating some other field.
                        if (showField) {
                          setActiveField(false)
                        }
                      }}
                      onChange={({ quantity, colli }) => {
                        const editProperty = row.edit_property || row.key
                        const editColliProperty = row.edit_colli_property

                        let updateData = { [editProperty]: quantity }

                        if (editColliProperty) {
                          updateData[editColliProperty] = colli
                        }

                        if (row.map_edit) {
                          updateData = row.map_edit({
                            data: updateData,
                            lines,
                          })
                        }

                        onChange(updateData)
                      }}
                      onControlKey={onControlKey}
                      onLabelClick={() => setActiveField(fieldId)}
                      right={row.right}
                      showField={showField}
                      value={value || ''}
                      valueColli={valueColli}
                    />
                  </QuantityFieldContainer>
                )}

                {suffixRenderFunction && (
                  <div
                    style={{
                      width: row.suffix_render_width
                        ? `${row.suffix_render_width}px`
                        : 'auto',
                    }}
                  >
                    {suffixRenderFunction({
                      lines,
                      variant,
                    })}
                  </div>
                )}
              </QuantitySuffixContainer>
            )}
          </TableColumn>
        )
      }

      //
      // COLUMNS
      //
      columnCount = columns.length
      for (let column of columns) {
        let edit = false
        // When editing columns we should NOT use editableRow, since
        // we can easily have editable columns but no editable rows.
        if (
          primaryRow &&
          editColumn !== false &&
          editColumn.columnKey === column.key &&
          editColumn.rowId === primaryRow.id
        ) {
          edit = true
        }

        const renderData = {
          columnValues,
          context: dataContext,
          editProperty: column.edit_property,
          editable: column.editable,
          edit: edit,
          horizontalAttributeValues,
          lines,
          onColumnValueChange: data =>
            onColumnValueChange(
              primaryRow,
              {
                [column.key]: [data],
              },
              {
                createOnMissing: true,
                defaultRowData: createDefaultLineData(
                  tableSectionData,
                  defaultRowData,
                  subSection,
                  columns,
                  primaryRow
                ),
                product,
                variants,
                lines: primaryRow.lines,
                type: 'update',
                updates: data,
              }
            ),
          onEdit: editMode => {
            if (editMode) {
              splitsDispatcher({
                type: 'edit_column',
                row: primaryRow,
                column,
              })
            } else {
              splitsDispatcher({
                type: 'cancel_edit_column',
              })
            }
          },
          product,
          row: primaryRow,
          updateLines: onUpdateLines,
          rowValues: {}, //TODOrowValuesByHorizontalAttribute,
          variants,
        }

        const rowKeyOfColumn = column.rows[0]
        const rowOfColumn = rows.find(r => r.key === rowKeyOfColumn)

        const value = rowOfColumn ? rowOfColumn.columnValues[column.key] : []
        const style = column.style ? column.style(renderData) : {}
        const shouldRenderForRow = value !== undefined // TODOcolumn.rows.includes(row.key)

        variantRow.push(
          <TableColumn
            className="product-table-hover-column test"
            key={column.key}
            style={{ ...columnConfigToStyle(column), ...style }}
          >
            {shouldRenderForRow &&
              renderValue({
                data: renderData,
                config: column,
                value: value,
              })}
          </TableColumn>
        )
      }

      //
      // ACTIONS
      //
      actionCount = useActions.length
      for (let action of useActions) {
        if (editableRow) {
          const ActionComponent = action.render

          variantRow.push(
            <TableColumn
              className="product-table-hover-column"
              key={`actions-${action.key}`}
              width="1%"
            >
              <ActionComponent
                horizontalAttributeValues={horizontalAttributeValues}
                lines={editableRow.lines}
                //TODO:linesByHorizontalAttribute,
                row={editableRow}
                splitsDispatcher={splitsDispatcher}
                subSectionKey={subSection.subSectionKey}
                subSectionSplitData={subSection.subSectionSplitData}
                updateLines={onUpdateLines}
                variantsByHorizontalAttribute={variantsByHorizontalAttribute}
                //TODO
                rowValuesByHorizontalAttribute={{}}
              />
            </TableColumn>
          )
        }
      }

      generatedRows.push(
        <TableDataSectionRow
          data-testid={`variant-row-${variant.id}`}
          key={keyGen(`row-${columnKey}-${subSectionKey}`)}
          /*TODO: last={k === subSection.rows.length - 1}*/
        >
          {variantRow}
        </TableDataSectionRow>
      )
    }
  }

  // In no product headers mode we dont want any space between variants of different products
  if (isLast && !noProductHeadersMode) {
    const imageColumn = productImage === true || variantImages === true ? 1 : 0
    generatedRows.push(
      <VerticalAttributeSplitRow key="vertical-attribute-split-row">
        <td colSpan={1 + imageColumn + columnCount + rowCount + actionCount} />
      </VerticalAttributeSplitRow>
    )
  }

  return generatedRows
}

export default TraditionalVariantRow

const TableDataSubSectionRow = styled(TableRow)`
  td {
    border-bottom: ${({ last = false }) =>
      last ? 'none' : '1px dashed #e6e6e6'};
    border-top: 1px dashed #e6e6e6;
  }
`

const TableDataSectionRow = styled(TableRow)`
  td {
    border-top: ${({ last }: { last: boolean }) =>
      last ? '1px solid #e0e0e0' : 'none'};
  }
`

const SectionHeader = styled.span``

const SectionHeaderColumn = styled(TableColumn)``

const SectionHeaderContentContainer = styled.div`
  display: flex;
`

const SectionHeaderLabel = styled.div`
  flex: 1;
`

// background important is needed to override chrome print styles
const SubsectionHeaderColumn = styled(TableColumn)`
  background: #f9f9f9 !important;
  -webkit-print-color-adjust: exact;
  color: #b5b5b5;
  font-size: 10px;
  font-style: italic;
  font-weight: bold;
  padding-right: 5px;
  text-align: right;
`

const SectionHeaderIcon = styled.div`
  color: #a7a7a7;
  font-size: 10px;
  display: flex;
  align-items: center;
`

const VariantImageColumn = styled(TableColumn)`
  vertical-align: middle;

  > * {
    display: none;
  }

  @media print {
    > * {
      display: block;
    }
  }

  @media (min-width: 1025px) {
    > * {
      display: block;
    }
  }
`

const ProductNameLabel = styled.div`
  font-weight: bold;
`

const QuantityFieldContainer = styled.div`
  ${({ hasSuffix }) =>
    hasSuffix
      ? css`
          flex: 1;
          margin-bottom: auto;
        `
      : ''};
`

const QuantitySuffixContainer = styled.div`
  display: ${({ hasSuffix }) => (hasSuffix ? 'flex' : 'block')};
`
